/* You can add global styles to this file, and also import other style files */
html, body { height: 100%; }
body { margin: 0; }

router-outlet.router-flex + * {
  //display: flex;
  //flex: 1 1 auto;
  //flex-direction: column;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
}

fieldset {
  border-style: none;
}

legend {
  font-size: unset;
  font-weight: 700;
  margin-bottom: 5px;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px; width: 1px;
  margin: -1px; padding: 0; border: 0;
}

.clip_bg_right_arrow {
  clip-path: polygon(
      0 0,
      calc(100% - 14px) 0,
      100% 50%,
      calc(100% - 14px) 100%,
      0 100%,
      0 0
  )
}

.separator {
  border-bottom: 2px solid;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

.fmsf_mat_override .mat-cell {
  padding: 0px 8px 0px 0px;
}

.fmsf_mat_override .mat-row:hover .mat-cell {
  border-color: currentColor;
}

.fmsf_mat_override .mat-row .mat-cell {
  border-bottom: 1px solid transparent;
  border-top: 1px solid transparent;
}

.fmsf_mat_override .mat-checkbox-layout {
  white-space: normal;
}

.fmsf_mat_override th.mat-header-cell {
  padding-left: 6px;
}

// can apply mat-form-field-readonly class to remove outline/underline around a mat field (still need readonly=true on field itself)
.mat-form-field-readonly {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-outline {
        .mat-form-field-outline-start {
          border-width: 0;
        }
        .mat-form-field-outline-gap {
          border-width: 0;
        }
        .mat-form-field-outline-end {
          border-width: 0;
        }
      }
    }

    .mat-form-field-underline {
      width: 0;
    }
  }
}
