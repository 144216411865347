@use '@angular/material' as mat;
@use '@angular/cdk';
@import 'styles';

//$my-custom-typography: mat.define-typography-config(
//  $body-1:  mat.define-typography-level(16px),
//);
//
//@include mat.core($my-custom-typography);

@include mat.core();
@include mat.strong-focus-indicators();
@include cdk.a11y-visually-hidden();

// palette vars from angular-client/node_modules/@angular/material/core/theming/_palette.scss
$my-primary: mat.define-palette(mat.$deep-purple-palette, 500); // #673ab7
$my-accent: mat.define-palette(mat.$amber-palette, A200, A100, A400); // #ffd740

// The "warn" palette is optional and defaults to red if not specified.
$my-warn: mat.define-palette(mat.$red-palette, 800); // #d32f2f

$my-theme: mat.define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
    warn: $my-warn,
  )
));

@include mat.all-component-themes($my-theme);
@include mat.strong-focus-indicators-theme(mat.get-color-from-palette($my-warn));

@mixin theme_set_vars($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  // some global styles for theming
  .color_primary {
    color: mat.get-color-from-palette($primary)
  }

  .bg_color_primary {
    background-color: mat.get-color-from-palette($primary);
  }

  .hover_primary:hover {
    color: mat.get-color-from-palette($primary);
  }

  .color_accent {
    color: mat.get-color-from-palette($accent);
  }

  .bg_color_accent {
    background-color: mat.get-color-from-palette($accent);
  }

  .hover_accent:hover {
    color: mat.get-color-from-palette($accent);
  }

  .color_warn {
    color: mat.get-color-from-palette($warn);
  }

  .bg_color_warn {
    background-color: mat.get-color-from-palette($warn);
  }

  .hover_warn:hover {
    color: mat.get-color-from-palette($warn);
  }

  .color_secondary {
    // comment or grey styled
    color: mat.get-color-from-palette($foreground, secondary-text);
  }

  .bg_color_secondary {
    // comment or grey styled
    background-color: mat.get-color-from-palette($background, background);
  }

  .border_color_secondary {
    // comment or grey styled
    border-color: mat.get-color-from-palette($foreground, secondary-text);
  }

  //.mat-row:nth-child(odd) {
  //  background-color: mat.get-color-from-palette($accent);
  //}
}

@include theme_set_vars($my-theme);

// global styles that modify themed elements
.fmsf-mat-header {
  @extend .bg_color_warn;
  @extend .clip_bg_right_arrow;
  margin: 0;
  color: white;
}

.fmsf-rem-header-margin .mat-card-header-text {
  margin: 0;
}
